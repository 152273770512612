<!--配置管理-->
<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-ai-c flex-jc-sb headerBox">
            <el-page-header content="基本配置管理 > "></el-page-header>
            <el-button type="primary" size="small" icon="el-icon-upload2" style="width:100px;" @click="submit">提交</el-button>
        </div>

        <!--form表单-->
        <div class="formBox shopBox">
            <!-- <el-tabs v-model="activeName" style="padding:0 20px;;box-sizing:border-box;">
                <el-tab-pane label="基本配置" name="first"></el-tab-pane>
                <el-tab-pane label="充值配置" name="second"></el-tab-pane>
                <el-tab-pane label="设置优惠券" name="third"></el-tab-pane>
            </el-tabs> -->

            <!--基本配置-->
            <div class="configMain" v-if="activeName == 'first' && list.length!=0">
                <div style="margin-bottom:50px">
                    <el-divider content-position="left"><div class="mainColor">积分设置</div></el-divider>
                    <!--积分配置-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">积分抵用比例<i class="el-icon-warning-outline" @click="tipRule(1)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['integral_rate_rmb'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">购物返积分
                            <i class="el-icon-warning-outline" @click="tipRule(4)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['integral_order'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                </div>

                <div style="margin-bottom:50px">
                    <el-divider content-position="left"><div class="mainColor">商品设置</div></el-divider>
                    <!--商品设置-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label>商品库存预警</label>
                        <el-input style="width:40%" v-model="list['inventory_warning'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置配送超时罚款" size="mini"></el-input>
                    </el-row>
                </div>

                <div style="margin-bottom:50px">
                    <el-divider content-position="left"><div class="mainColor">配送设置</div></el-divider>
                    <!--配送配置-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label>配送商品起送价</label>
                        满
                        <el-input style="max-width:11%;min-width:110px" class="input" v-model="list['send_free_min'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini">
                            <template slot="append">元</template>
                        </el-input>免费配送，
                        不满起送价收取
                        <el-input style="max-width:11%;min-width:110px" class="input" v-model="list['send_fee'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini">
                            <template slot="append">元</template>
                        </el-input>配送费
                    </el-row>
                    <!--配送罚款-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label>配送超时罚款<i class="el-icon-warning-outline" @click="tipRule(2)"></i></label>
                        <el-input style="width:40%" v-model="list['send_fine'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置配送超时罚款" size="mini">
                            <template slot="append">元</template>
                        </el-input>
                    </el-row>
                    <!--配送范围-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label>配送范围</label>
                         <el-input style="width:40%" v-model="list['send_distance'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置配送范围" size="mini">
                            <template slot="append">km</template>
                        </el-input>
                    </el-row>
                </div>
                
                <div>
                    <el-divider content-position="left"><div class="mainColor">分销提现设置</div></el-divider>
                    <!--会员提现-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label for=""> 
                            最低提现金额
                            <i class="el-icon-warning-outline" @click="tipRule(3)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['withdraw_min'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="配置最低提现金额" size="mini"></el-input>
                    </el-row>
                </div>
                

                <div>
                    <el-divider content-position="left"><div class="mainColor">评价积分设置</div></el-divider>
                    <!--积分配置-->
                    <el-row type="flex" align="middle" class="config-row">
                        <label>好评赠送积分</label>
                        <el-input style="width:40%" v-model="list['integral_evaluate_good'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label>中评赠送积分</label>
                        <el-input style="width:40%" v-model="list['integral_evaluate_middle'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label>差评赠送积分</label>
                        <el-input style="width:40%" v-model="list['integral_evaluate_bad'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                </div>
            </div>

            <!--预存活动-->
            <div class="configMain" v-if="activeName == 'second'">
                <el-divider content-position="left">
                    <div class="mainColor">充值配置</div>
                </el-divider>
                <el-row type="flex" align="middle" class="config-row">
                    <label style="width:100px">预存设置：</label>
                    满
                    <el-input style="width:10%" class="input" v-model="list[0].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                    返
                    <el-input style="width:10%" class="input" v-model="list[1].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                </el-row>
            </div>

            <!--设置优惠券-->
            <div class="configMain" v-if="activeName == 'third'">
                <el-divider content-position="left">
                    <div class="mainColor">新人优惠（所有商品）</div>
                </el-divider>
                <el-row type="flex" align="top" class="config-row">
                    <label style="width:100px;padding-top:16px">优惠券设置：</label>
                    <el-row style="width:calc(100% - 100px)">
                        <div v-for="(item,index) in formData.newCoupon" :key="index" style="margin-top:10px">
                            满
                            <el-input style="width:10%" class="input" v-model="item.value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            返
                            <el-input style="width:10%" class="input" v-model="item.value1" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            <el-radio-group v-model="item.radio" style="margin-right:20px">
                                <el-radio :label="1">使用</el-radio>
                                <el-radio :label="2">停用</el-radio>
                            </el-radio-group>
                            <el-button type="success" v-if="index==0" icon="el-icon-plus" style="padding:8px" @click="addCoupon('newCoupon')" circle></el-button>
                            <el-button type="danger" v-else icon="el-icon-minus" style="padding:8px" @click="deleteCoupon('newCoupon',index)" circle></el-button>
                        </div>
                    </el-row>
                    
                </el-row>
                <el-divider content-position="left">
                    <div class="mainColor">会员购物（所有商品）</div>
                </el-divider>
                
                <el-row type="flex" align="top" class="config-row">
                    <label style="width:100px;padding-top:16px">优惠券设置：</label>
                    <el-row style="width:calc(100% - 100px)">
                        <div v-for="(item,index) in formData.coupon" :key="index" style="margin-top:10px">
                            满
                            <el-input style="width:10%" class="input" v-model="item.value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            返
                            <el-input style="width:10%" class="input" v-model="item.value1" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            <el-radio-group v-model="item.radio" style="margin-right:20px">
                                <el-radio :label="1">使用</el-radio>
                                <el-radio :label="2">停用</el-radio>
                            </el-radio-group>
                            <el-button type="success" v-if="index==0" icon="el-icon-plus" style="padding:8px" @click="addCoupon('coupon')" circle></el-button>
                            <el-button type="danger" v-else icon="el-icon-minus" style="padding:8px" @click="deleteCoupon('coupon',index)" circle></el-button>
                        </div>
                    </el-row>
                    <!-- 满
                    <el-input style="width:10%" class="input" v-model="list[0].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                    返
                    <el-input style="width:10%" class="input" v-model="list[1].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input> -->
                </el-row>
            </div>
        </div>

        <!--使用规则-->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="550px">
            <div v-html="tipContent" style="line-height:2"></div>
        </el-dialog>
    </div>
</template>

<script>
    import {configAllDataApi,configSaveDataApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                activeName:'first',
                title:'',
                tipContent:'',
                list:[],
                
                formData:{
                    newCoupon:[{
                        radio:1
                    }],
                    coupon:[{
                        radio:1
                    }],
                },

                dialogFormVisible:false
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData: function(){
                configAllDataApi().then(response=>{
                    this.list = response.list;
                })
            },
            
            tipRule: function(num){
                this.dialogFormVisible = true;
                if (num == 1) {
                    this.title = '积分抵用规则';
                    this.tipContent = '积分使用：' + this.list['integral_rate_rmb'].value + '积分1元<br>积分使用的限制：根据商品积分的设置不同，部分商品不能使用积分或只能使用积分抵扣部分金额'
                }else if(num == 2){
                    this.title = '配送超时罚款规则';
                    this.tipContent = '配送员配送超时，平台支付超时罚款' + this.list['send_fine'].value + '元'
                } else if(num == 3){
                    this.title = '会员提现规则';
                    this.tipContent = '会员最低提现金额' + this.list['withdraw_min'].value + '元（充值会员的钱无法提现！）'
                }else if(num == 4){
                    this.title = '购物返积分规则';
                    this.tipContent = '购物返积分比例：1表示积分按照1:1比例返还，即购物金额100元返100个积分'
                }
            },
            //添加新人优惠
            addCoupon: function(selectCoupon){
                let idx = this.formData[selectCoupon].length - 1;
                let arr = {
                    value:'',
                    value1:'',
                    radio:1
                }
                if (this.formData[selectCoupon].length == 0) {
                    this.formData[selectCoupon].push(arr);
                }else{
                    if (!this.formData[selectCoupon][idx].value || !this.formData[selectCoupon][idx].value1) {
                        this.$message({
                            message: selectCoupon == 'newCoupon' ? '新人优惠券金额不能有空！' : '会员购物商品优惠券金额不能有空',
                            type: 'error'
                        })
                        return;
                    } else {
                        this.formData[selectCoupon].push(arr);
                    }
                }
            },
            //删除新人优惠
            deleteCoupon:function(selectCoupon,index){
                this.formData[selectCoupon].splice(index,1);
            },
            //设置保存
            submit: function(){
                switch (this.activeName) {
                    case 'first':
                        configSaveDataApi(this.list).then(()=>{
                            this.getData();
                            this.$message({
                                type: 'success',
                                message: '已设置!'
                            });
                        })
                        break;
                
                    default:
                        break;
                }
            },
        },
    }
</script>